<template>
  <main id="content" role="main">

    <!-- Hero Section -->
    <div class="position-relative border-bottom">
      <div class="gradient-y-overlay-sm-gray-900 bg-img-hero" style="background-image:linear-gradient(to bottom, rgba(19, 24, 54, 0.80), rgba(19, 24, 54, 0.10)),url(./assets/img/cover-climb.jpg);">
        <!-- Slide #1 -->
        <div class="container d-md-flex align-items-md-center vh-md-70 space-top-4 space-bottom-3 space-md-0">
          <div class="w-80 w-lg-50">
            <span class="d-block h3 text-white mb-2">
              Check out all your
            </span>
            <h1 class="text-white display-4 mb-0">
               <span class="text-primary">Activities</span><br>
               <span class="text-white">Donations</span><br>
               <span class="text-info">Places</span>
            </h1>
          </div>
        </div>
        <!-- End Slide #1 -->
        <div class="postion-absolute bottom-0 right-0 left-0 text-center">
            <div class="container-fluid text-white py-5" style="opacity:0.4">
                <i class="fa fa-map-marker-alt"></i>
                <span> Tonsai, Thailand - Photo by <a href="https://unsplash.com" target="_blank" style="color:inherit;text-decoration:underline">Hu Chen</a></span>
            </div>
        </div>
      </div>
    </div>
    <!-- End Hero Section -->

    <!-- Description Section -->
    <div class="container space-2">
      <div class="row">
        <div class="col-lg-10">
        <!-- Step -->
          <ul class="step step-icon-sm">
            <li class="step-item">
              <div class="step-content-wrapper">
                <div class="step-avatar step-avatar-sm">
                  <img class="step-avatar-img" src="../../public/assets/img/strava.png" alt="Image Description">
                </div>
                <div class="step-content">
                  <h5>Head of IT Department</h5>
                  <span class="d-block text-dark">Spec - London</span>
                  <small class="d-block mb-4">December 2016 to Present</small>
                  <p class="text-body mb-0">The company has high expectations and using OKRs there is a mutual understanding of expectations and performance.</p>
                </div>
              </div>
            </li>

            <li class="step-item">
              <div class="step-content-wrapper">
                <div class="step-avatar step-avatar-sm">
                  <img class="step-avatar-img" src="../../public/assets/img/woosports.png" alt="Image Description">
                </div>
                <div class="step-content">
                  <h5>Senior Software Engineer</h5>
                  <span class="d-block text-dark">Mailchimp - London</span>
                  <small class="d-block mb-4">November 2014 to December 2016</small>
                  <p class="text-body mb-0">This is an excellent company and they reward their employees. It's becoming a big company but it's still private, so the culture is as good as it gets at 1,000+ employees if you ask me. Managers are still adapting to the growth I think, but everyone has to. Great place to work.</p>
                </div>
              </div>
            </li>

            <li class="step-item">
              <div class="step-content-wrapper">
                <div class="step-avatar step-avatar-sm">
                  <img class="step-avatar-img" src="../../public/assets/img/instagram.png" alt="Image Description">
                </div>
                <div class="step-content">
                  <h5>Junior Software Engineer</h5>
                  <span class="d-block text-dark">Google - London</span>
                  <small class="d-block mb-4">January 2014 to November 2014</small>
                  <p class="text-body mb-0">Work in Google is one of the beautiful experience I can do in my entire life. There are a lot of interesting thing to learn and manager respect your time and your personality.</p>
                </div>
              </div>
            </li>

            <li class="step-item">
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-soft-dark">
                  <i class="fas fa-briefcase"></i>
                </span>
                <div class="step-content">
                  <h5>Internship</h5>
                  <span class="d-block text-dark">Htmlstream under Pixeel Ltd. - London</span>
                  <small class="d-block mb-4">December 2013 to January 2014</small>
                  <p class="text-body mb-0">My first steps...</p>
                </div>
              </div>
            </li>
          </ul>
          <!-- End Step -->
        </div>
      </div>
    </div>

  </main>
</template>

<script setup>
  // import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  const store = useStore()
  const router = useRouter()

  if (!store.state.userProfile.registrationCompleted){ router.push('/register') }

</script>

<style scoped>
</style>
